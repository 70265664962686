import { request } from "@/request/http"; 
/**
 * @name: 慕课课程页接口
 * @author: wyf
 * @date: 2023-07-26
 */
export default {
   // 获取开放课程列表
   getData: (data) => {
    return request("POST", `/index.php/adminOpenCourse/getData`, data);
  },
  // 修改开放课程状态
  updateStatus: (data) => {
    return request("POST", `/index.php/adminOpenCourse/updateStatus`, data);
  },
}