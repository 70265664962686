<template >
    <div class="openCourse">
        <!-- 证书列表 -->
        <el-card>
          <div class="search">
              <!-- 筛选课程状态 -->
               <div class="status">
                <el-select v-model="value" placeholder="请选择" @change ="getData">
                  <el-option
                    v-for="item in options"
                    
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
               </div>

                <!-- 搜索题目 -->
               <div class="inputSearch">
                <el-input v-model="search" placeholder="搜索" @change="getData"></el-input>
                <i class="el-icon-search" @click="getData"></i>
               </div>
            </div>
            <el-table
                ref="multipleTable"
                :data="openCourseList"
                style="width: 100%">
                    <!-- 序号 -->
                    <el-table-column
                    min-width="60"
                      type="index"
                      :index="indexMethod"
                      label="序号"
                      width="100"> 
                    </el-table-column>
                    <!--申请时间  -->
                    <el-table-column
                      label="申请时间"
                      prop="addtime"
                      >
                    </el-table-column>
                    <!-- 姓名 -->
                    <el-table-column
                      label="姓名"
                      prop="team">
                    </el-table-column>
                    <!-- 学校 -->
                    <el-table-column
                    prop="schoolName"
                      label="学校" min-width="60">
                    </el-table-column>
                    <!-- 慕课名称 -->
                    <el-table-column
                      prop="name"
                      label="慕课名称" min-width="180">
                    </el-table-column>
                    <!-- 状态 -->
                    <el-table-column
                    min-width="50"
                      prop="status"
                      label="状态">
                      <template v-if="value === '3'">
                        <el-switch
                          v-model="openScoreFlag"
                          active-color="#13ce66"
                          inactive-color="#ff4949">
                        </el-switch>
                      </template>
                    </el-table-column>
                    <!-- 操作 -->
                    <el-table-column
                    label="操作">
                        <template slot-scope="scope">
                            <!-- <div class="operatorItem floatLeft">查看详情</div> -->
                            <div v-if="value === '3' || value === '2'" class="operatorItem floatLeft" style="color: #f00;">删除</div>
                            <div v-if="value === '1'" class="operatorItem floatLeft" @click="modifyRatifyDialog(scope.row.id)">批准</div>
                            <div v-if="value === '1'" class="operatorItem floatLeft" @click="modifyRefuseStatus(scope.row.id)">拒绝</div>

                        </template>
                    </el-table-column>
            </el-table>
            <!-- 证书分页 -->
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="page"
                background
                :page-size="pagesize"
                layout="prev, pager, next"
                :total="openCourseTotal">
            </el-pagination>
        </el-card>

        <!-- 点击批准的弹窗 -->
        <el-dialog
          title="提示"
          :visible.sync="modifyRatifyDialogVisible"
          width="30%"
          class="modifyRatifyDialog"
        >
          <span>是否批准通过该慕课课程</span>
          <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="modifyRatifyDialog = false">取 消</el-button>
            <el-button size="mini" style="background-color: #0D5570;" type="primary" @click="modifyStatus('3')">确 定</el-button>
          </span>
        </el-dialog>

      <!-- 点击拒绝的弹窗 -->
      <el-dialog
          title="提示"
          :visible.sync="modifyRefuseDialogVisible"
          width="30%"
          class="modifyRatifyDialog"
        >
          <span>是否确认拒绝</span>
          <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="modifyRatifyDialog = false">取 消</el-button>
            <el-button size="mini" style="background-color: #0D5570;" type="primary" @click="modifyStatus('2')">确 定</el-button>
          </span>
      </el-dialog>

    </div>
  
</template>

<script>
    // 引入试题列表js
    import openCourse from "@/assets/js/manager/openCourse/openCourse.js";
    export default {
     ...openCourse
    }
</script>

<style>
.openCourse .confirmButton{
    padding: 0 .7vw !important;
    height: 2.0833vw!important;
    background-color: #0D5570!important;
    color:#FFFFFF!important;
    border-radius: 0.2083vw!important;
    font:normal normal normal 0.8333vw  Source Han Sans CN!important;
}
.openCourse .el-input__inner {
    font-size: 0.8333vw;
    /* height: 2.0833vw; */
}
.openCourse .el-input,.openCourse .el-select{
    /* width:20vw!important; */
}
.openCourse .el-form--label-left .el-form-item__label {
    font-size: 0.8333vw;
}
.openCourse .cancelButton{
    padding: 0 .7vw!important;
    height: 2.0833vw!important;
    background-color: #FFFFFF!important;
    color:#000000!important;
    border: 0.0521vw solid #CCCCCC!important;
    border-radius: 0.2083vw!important;
    font:normal normal normal 0.8333vw  Source Han Sans CN!important;
}
.openCourse .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D5570;
    color: #FFF;
}
.openCourse .el-dialog{
  border-radius: 0.625vw !important;
  margin-top: 5vh !important;
}
.openCourse .el-table__body-wrapper {
    height: 67vh;
    overflow: scroll;
    position: relative;
    scrollbar-width: none;
            -ms-overflow-style: none;
            overflow: scroll;
}

.openCourse .el-form{
    max-height: 66vh;
    overflow: scroll;
    position: relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border-radius: 0.625vw !important;
}
.openCourse .el-table__body-wrapper::-webkit-scrollbar,.openCourse .el-form::-webkit-scrollbar{
  display: none;
}
.openCourse .el-upload {
        border: 1px dashed #d9d9d9 !important;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
.openCourse .avatar-uploader{
  /* text-align:center; */
}
.openCourse .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.openCourse .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.openCourse .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.openCourse .searchschool .el-input,.openCourse .searchschool .el-select{
  width: 12vw !important;
}
</style>
<style lang="scss" scoped>
// 引入试题列表组件css
    @import "@/assets/css/manager/openCourse/openCourse.scss";
</style>