import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import { encryptCode,decryptCode } from "@/utils/function";
import {request_sign} from '@/utils/const';
import API from '@/api/manager/openCourse/openCourse.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
        // 页面名称
        name: "openCourse",
        data()
        {
            return{
                // 课程状态
                options: [{
                    value: '0',
                    label: '已保存未申请'
                },{
                    value: '1',
                    label: '申请中'
                }, {
                    value: '3',
                    label: '已批准'
                }, {
                    value: '2',
                    label: '已拒绝'
                }],
                // 课程状态 0：已保存未申请 1：申请中 2：拒绝 3：已上线 默认传1
                value: '1',
                // 搜索关键词
                search:'',
                page:1,
                pagesize:10,
                openCourseTotal: 0,
                openCourseList:[],
                openScoreFlag: true,
                modifyRatifyDialogVisible: false,
                id:'',
                modifyRefuseDialogVisible: false,
            }
        },
        /**
         * @name: 生命周期创建
         * @desc: 页面创建时获取数据
         * @author: lxy
         * @date: 20210908
         */
        created()
        {
            let self=this;
            if(this.$router.currentRoute.query.params)
            {
                let params = JSON.parse(decryptCode(this.$router.currentRoute.query.params));
                self.page = Number(params?.page); 
                self.search = params?.search;
                self.pageSize = params?.pageSize;
                self.value = params?.status;
            }
            self.getData();
        },

        methods:{

             /**
             * @name: 分页序号递增
             * @author: wyf
             * @date: 2023-07-26
             */
             indexMethod(index) {
                let currentPage = this.page; // 当前页码
                let pageSize = this.pagesize; // 每页条数
                return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
            },
            /**
             * @name: 点击分页
             * @author: wyf
             * @date: 2023-07-26
             */
            handleCurrentChange(val) {
                const self = this;
                self.page = val;
                self.getData();
            },

            /**
             * @name: 获取开放课程列表
             * @author: wyf
             * @date: 2023-07-26
             */
            getData() {
                nprogressStart();
                this.$Loading.show();

                let params = encryptCode({
                    search: this.search,
                    status:this.value,
                    page:this.page,
                    pageSize:this.pagesize
                })

                this.$router.push({
                    path: "/manager/openCourse",
                    query: {
                        params:params
                    },
                });

                let data = {sign: request_sign,params: params}
                API.getData(data)
                .then(res =>{
                    nprogressClose();
                    this.$Loading.hide();
                    this.openCourseList = res.list
                    this.openCourseTotal = res.number
                    // 根据返回的数字 替换成显示的文字
                    this.openCourseList.forEach(item => {
                        if(item.status==='1') {
                            item.status = '申请中'
                        }
                        if(item.status==='0') {
                            item.status = '已保存'
                        }
                        if(item.status==='2') {
                            item.status = '已拒绝'
                        }
                        if(item.status==='3') {
                            item.status = '已上线'
                        }
                    });
                })
            },

            /**
             * @name: 点击批准和拒绝
             * @author: wyf
             * @date: 2023-07-26
             * @params: status 拒绝和批准上线
             */
            modifyStatus(status) {
                // nprogressStart();
                // this.$Loading.show();
                // this.modifyRatifyDialogVisible = false;
                if(status === '3') {
                    this.modifyRatifyDialogVisible = false;
                }else 
                if(status === '2') {
                    this.modifyRefuseDialogVisible = false;

                }
                let str = encryptCode({
                    id: this.id,
                    status: status
                })
                let data = { sign: request_sign,params: str }
                API.updateStatus(data)
                .then(res => {
                    // console.log(res);
                    // nprogressClose();
                    // this.$Loading.hide();
                    this.getData()
                })
            },

            /**
             * @name: 显示批准的弹窗
             * @author: wyf
             * @date: 2023-07-26
             * @params: id 数据主键id
             */
            modifyRatifyDialog(id) {
                this.modifyRatifyDialogVisible = true;
                this.id = id
            },

            /**
             * @name: 显示拒绝的弹窗
             * @author：wyf
             * @date：2023-07-26
             * @params: id 数据主键id
             */
            modifyRefuseStatus(id) {
                this.modifyRefuseDialogVisible = true;
                this.id = id
            },
            showInfo(e) {
                console.log(1);
            }

        }
        
};